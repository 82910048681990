<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { defineComponent, onMounted, ref } from "vue";
import { getTopIllness } from "@/api/chart";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: "pie",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "", // 主题色: dark
  },
  setup: () => {
    const option = ref({
      title: {
        text: "疾病分布",
        subtext: '疾病的总体分布情况',
        top: "top",
        left: "left",
        textStyle: {
          color: '#032954'
        },
        subtextStyle: {
          color: '#787878'
        }
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        bottom: "0%",
        left: "center",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
    });

    const getTopIllnessData = async () => {
      const res = await getTopIllness({});

      var seriesData = res.response.map((item) => {
        return { value: item.result.total, name: item.illness.name };
      });

      const output = seriesData.reduce((previous, current) => {
        const item = previous.find((o) => o.name === current.name);
        item
          ? (item.value = item.value + current.value)
          : previous.push(current);
        return previous;
      }, []);
      
      option.value.series[0].data = output;
    };

    onMounted(() => {
      getTopIllnessData();
    });

    return { option };
  },
});
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>