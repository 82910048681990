import service from '@/utils/request'

// 获取疾病信息列表
export const getTopIllness = (params) => {
    return service({
        url: '/top/illness',
        method: 'get',
        params
    })
}

// 获取用户分布
export const getTopUsers = (params) => {
    return service({
        url: '/top/users',
        method: 'get',
        params
    })
}