<template>
  <v-chart class="chart" :option="option" ref="map" autosize />
</template>

<script>
import { use, registerMap } from "echarts/core";
import { ref, defineComponent, onMounted } from "vue";
import VChart, { THEME_KEY } from "vue-echarts";

import { MapChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";

import chinaMap from "./china.json";
import { getTopUsers } from "@/api/chart";

use([MapChart, VisualMapComponent, TitleComponent, TooltipComponent]);

registerMap("china", chinaMap);

export default defineComponent({
  name: "v-map",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "",
  },
  setup: () => {
    var geoCoordMap = {
      北京市: [116.405285, 39.904989],
      天津市: [117.190182, 39.125596],
      河北省: [114.502461, 38.045474],
      山西省: [112.549248, 37.857014],
      内蒙古自治区: [111.670801, 40.818311],
      辽宁省: [123.429096, 41.796767],
      吉林省: [125.3245, 43.886841],
      黑龙江省: [126.642464, 45.756967],
      上海市: [121.472644, 31.231706],
      江苏省: [118.767413, 32.041544],
      浙江省: [120.153576, 30.287459],
      安徽省: [117.283042, 31.86119],
      福建省: [119.306239, 26.075302],
      江西省: [115.892151, 28.676493],
      山东省: [117.000923, 36.675807],
      河南省: [113.665412, 34.757975],
      湖北省: [114.298572, 30.584355],
      湖南省: [112.982279, 28.19409],
      广东省: [113.280637, 23.125178],
      广西壮族自治区: [108.320004, 22.82402],
      海南省: [110.33119, 20.031971],
      重庆市: [106.504962, 29.533155],
      四川省: [104.065735, 30.659462],
      贵州省: [106.713478, 26.578343],
      云南省: [102.712251, 25.040609],
      西藏自治区: [91.132212, 29.660361],
      陕西省: [108.948024, 34.263161],
      甘肃省: [103.823557, 36.058039],
      青海省: [101.778916, 36.623178],
      宁夏回族自治区: [106.278179, 38.46637],
      新疆维吾尔自治区: [87.617733, 43.792818],
      台湾省: [121.509062, 25.044332],
      香港特别行政区: [114.173355, 22.320048],
      澳门特别行政区: [113.54909, 22.198951],
    };

    var convertData = function (data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: data[i].value,
          });
        }
      }
      return res;
    };
    const option = ref({
      title: {
        text: "用户分布",
        subtext: "用户的总体分布情况",
        top: "top",
        left: "left",
        textStyle: {
          color: "#032954",
        },
        subtextStyle: {
          color: "#787878",
        },
      },
      tooltip: {
        trigger: "item",
        // formatter: "{a} <br/>{b} : {c}",
        formatter: function (params) {
          console.log("params", params);
          var num = 0;
          if (params.value) {
            num = params.value;
          }
          return params.seriesName +"<br/>"+ params.name +":    "+ num
        },
      },
      visualMap: {
        type: "piecewise",
        pieces: [
          { min: 1, max: 9, label: "1-9人", color: "rgb(255, 247, 171)" },
          { min: 10, max: 99, label: "10-99人", color: "rgb(253, 187, 63)" },
          {
            min: 100,
            max: 499,
            label: "100-499人",
            color: "rgb(233, 112, 42)",
          },
          { min: 500, max: 999, label: "500-999人", color: "rgb(192, 72, 51)" },
          {
            min: 1000,
            max: 4999,
            label: "1000-4999人",
            color: "rgb(150, 59, 40)",
          },
          {
            min: 1000,
            max: 10000000,
            label: "5000人以上",
            color: "rgb(102, 29, 21)",
          },
        ],
        bottom: "180px",
      },
      series: [
        {
          name: "区域",
          type: "map",
          map: "china",
          roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          silent: false, // 地图图形是否不响应和触发鼠标事件，默认为false，即响应和触发鼠标事件。
          label: {
            show: false,
            color: "rgb(109, 114, 120)",
          },
          itemStyle: {
            // 默认样式
            areaColor: "#fff", //省份块的颜色
            borderColor: "skyblue", //省份描边的颜色
            bowderWidth: 3, //描边的宽度
          },
          // 选中样式
          emphasis: {
            itemStyle: {
              areaColor: "#fff", //省份块的颜色
              borderColor: "skyblue", //省份描边的颜色
              bowderWidth: 3, //描边的宽度
            },
          },
          select: {
            itemStyle: {
              areaColor: "#fff", //省份块的颜色
              borderColor: "skyblue", //省份描边的颜色
              bowderWidth: 3, //描边的宽度
            },
          },
          data: [],
        },
      ],
    });

    const getTopUsersData = async () => {
      const res = await getTopUsers({});
      console.log("res", res);
      var users = res.response
        .filter((obj) => obj.province !== "")
        .map((element) => {
          return {
            name: element.province,
            value: element.total,
          };
        });
      // 测试数据
      // users = [
      //   { name: "北京市", value: 3 },
      //   { name: "香港特别行政区", value: 1 },
      //   { name: "台湾省", value: 2 },
      //   { name: "宁夏回族自治区", value: 6 },
      //   { name: "贵州省", value: 3 },
      //   { name: "湖南省", value: 4 },
      //   { name: "黑龙江省", value: 9 },
      //   { name: "江苏省", value: 10 },
      //   { name: "山西省", value: 30 },
      //   { name: "辽宁省", value: 11 },
      //   { name: "安徽省", value: 0 },
      // ];
      console.log("users", users);
      option.value.series[0].data = convertData(users);
      console.log("option", option);
    };

    onMounted(() => {
      getTopUsersData();
    });

    return {
      option,
    };
  },
});
</script>

<style scoped>
.chart {
  height: 650px;
}
</style>