<template>
  <div class="dashboard">
    <el-card class="box-card">
      <pie></pie>
    </el-card>
    <el-card class="box-card">
      <map-chart></map-chart>
    </el-card>
  </div>
</template>

<script>
import pie from "./chart/pie.vue";
import MapChart from "./chart/map.vue";
export default {
  name: "Dashboard",
  components: {
    pie,
    MapChart,
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 20px 20px;
}
</style>
